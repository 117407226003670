<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title>
        {{ $t("SEO") }}
      </v-card-title>
      <v-card-text>
        <h3 class="mb-3">{{ $t("Page title") }}</h3>
        <v-row no-gutters>
          <v-col cols="12">
            <v-layout column >
              <v-select
                :items="pageLanguages"
                v-model="pageDefaultLanguage"
                class="max-w-select-lang mb-2"
                hide-details="auto"
                single-line
                outlined
                dense
              ></v-select>
              <template
                v-for="(translatedValue, translatedValueIndex) in page.Title"
              >
                <v-text-field
                  v-if="pageDefaultLanguage == translatedValueIndex"
                  v-model="page.Title[translatedValueIndex]"
                  :key="translatedValueIndex"
                  hide-details="auto"
                  class="textInputWidth"
                  outlined
                  dense
                >
                </v-text-field>
              </template>
            </v-layout>
          </v-col>
        </v-row>
        <h3 class="mb-3 mt-4">{{ $t("Page description") }}</h3>
        <v-row no-gutters>
          <v-col cols="12">
            <v-layout column align-start>
              <v-select
                :items="pageDescriptions"
                v-model="pageDefaultDescription"
                class="max-w-select-lang mb-2"
                hide-details="auto"
                single-line
                outlined
                dense
              ></v-select>
              <template
                v-for="(
                  translatedValue, translatedValueIndex
                ) in page.Description"
              >
                <template v-if="pageDefaultDescription == translatedValueIndex">
                  <v-textarea
                    v-model="page.Description[translatedValueIndex]"
                    :key="translatedValueIndex"
                    hide-details="auto"
                    class="textInputWidth"
                    rows="4"
                    outlined
                    dense
                  >
                  </v-textarea>
                </template>
              </template>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
    pageDescriptions: {
      type: [Array, Object],
    },
    pageDefaultLanguageProp: {},
    pageDefaultDescriptionProp: {},
    pageLanguages: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      pageDefaultLanguage: this.pageDefaultLanguageProp,
      pageDefaultDescription: this.pageDefaultDescriptionProp,
    };
  },
};
</script>

<style>
</style>